var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formulario-promocoes"},[_c('form',{staticClass:"formulario-container form-planilha",on:{"submit":function($event){$event.preventDefault();}}},[_c('select-segmentacao',{on:{"segmentacao":function($event){return _vm.defineTipo($event)}}}),_c('div',{staticClass:"formulario-linha linha-tripla media"},[_c('div',[_c('div',{staticClass:"formulario-item container-upload planilha-flex"},[_c('label',{staticClass:"formulario-item-label text-center"},[_vm._v(_vm._s(_vm.nomePlanilha))]),_c('input',{attrs:{"id":"input-planilha","type":"file","accept":".xls,.xlsx"},on:{"change":function($event){return _vm.atualizarPlanilha('planilha', 'dataPlanilha', $event)}}}),_c('div',{staticClass:"planilha-flex"},[_vm._m(0),_c('transition',{attrs:{"name":"fade"}},[(_vm.dataPlanilha)?_c('a',{staticClass:"remove-image",on:{"click":function($event){return _vm.removerPlanilha(
                  'planilha',
                  'dataPlanilha',
                  'input-planilha'
                )}}},[_vm._v("Remover")]):_vm._e()])],1)])]),_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"container-btns margin-top"},[_c('button',{staticClass:"btn btn-cancelar btn-padding",on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/treinamentos/listar')}}},[_vm._v(" Voltar ")]),_c('button',{staticClass:"btn btn-laranja btn-padding",attrs:{"disabled":_vm.$v.$invalid},on:{"click":function($event){return _vm.chamaConfirmacao()}}},[_vm._v(" Salvar ")])])],1),(_vm.erros)?_c('div',{key:"Lista",staticClass:"tabela-informacoes"},[_vm._m(3),_vm._l((_vm.tipoErros),function(item,index){return _c('div',{key:index,staticClass:"tabela-linha tabela-linha-informacoes"},[_c('div',{staticClass:"tabela-linha-nome"},[_c('p',{staticClass:"msg-erro-envio-planilha"},[_vm._v(_vm._s(item.aba))])]),_c('div',{staticClass:"tabela-linha-nome"},[_c('p',{staticClass:"msg-erro-envio-planilha"},[_vm._v(_vm._s(item.descricao))])]),_c('div',{staticClass:"tabela-linha-nome"},[_c('p',{staticClass:"msg-erro-envio-planilha"},[_vm._v(_vm._s(item.linha))])])])})],2):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"planilha-btn btn-icon",attrs:{"for":"input-planilha"}},[_c('i',{staticClass:"material-icons"},[_vm._v("send")]),_vm._v("Adicionar")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"planilha-flex"},[_c('label',{staticClass:"formulario-item-label text-center"},[_vm._v("Baixar Modelo Fechamento")]),_c('a',{staticClass:"baixa-planilha-exemplo",attrs:{"href":"/documentos/Modelo_Treinamento_Fechamento.xlsx","download":""}},[_c('div',{staticClass:"planilha-btn btn-icon"},[_c('i',{staticClass:"material-icons"},[_vm._v("cloud_download")]),_vm._v("Baixar ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"planilha-flex"},[_c('label',{staticClass:"formulario-item-label text-center"},[_vm._v("Baixar Modelo Parcial")]),_c('a',{staticClass:"baixa-planilha-exemplo",attrs:{"href":"/documentos/Modelo_Treinamento_Parcial.xlsx","download":""}},[_c('div',{staticClass:"planilha-btn btn-icon"},[_c('i',{staticClass:"material-icons"},[_vm._v("cloud_download")]),_vm._v("Baixar ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabela-linha tabela-linha-titulo"},[_c('div',{staticClass:"tabela-linha-nome"},[_c('p',[_vm._v("ABA")])]),_c('div',{staticClass:"tabela-linha-nome"},[_c('p',[_vm._v("ERRO")])]),_c('div',{staticClass:"tabela-linha-nome"},[_c('p',[_vm._v("LINHA")])])])
}]

export { render, staticRenderFns }